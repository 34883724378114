import './App.css';

function App() {
  return (
    <div className="App">
    <header>
      <nav class="navbar is-black" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" href="https://bulma.io">
          <img src="img/logo.svg"/>
        </a>

        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
          <a class="navbar-item">
            Work
          </a>

          <a class="navbar-item">
            Solves
          </a>

          <a class="navbar-item">
            Let's Talk
          </a>

        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            Since [2003]
          </div>
        </div>
      </div>
    </nav>
    </header>
    <section class="hero intro is-fullheight">
         <div class="hero-body is-black">
             <div>
                 <h2 class="stitle has-text-white">Celebrating <br/>20 years creating <br/>Digital experiences.</h2>
             </div>
         </div>
     </section>
     <section class="hero crafting is-fullheight has-background-white info">
       <div class="wtr"><lottie-player src="LNS_00.json" autoplay loop></lottie-player></div>
       <div class="wbl"><lottie-player src="LNS_00.json" autoplay loop></lottie-player></div>

          <div class="hero-body is-black">
            <div class="columns is-vcentered">
              <div class="column"><h2 class="stitle has-text-black has-text-left">Crafting waves of engaging, captivating & inspiring work</h2></div>
              <div class="column has-text-black">
                <p class="has-text-left">Our partnership entails much more than just the tasks we undertake; what truly matters to us is how we support you in driving progress. We collaborate closely with you as a unified and seamless team to help you achieve your business goals, attain profound understanding of your target audience, and develop optimal strategies for accomplishing your objectives.</p>
              </div>
            </div>
          </div>
      </section>

      <section class="hero individuals is-fullheight">
           <div class="hero-body is-black">
             <p class="title has-text-white">Over the course of two decades, we have had the privilege of collaborating with exceptionally gifted individuals, ideating for products and services intended to serve present and future generations. Whether driving progress, providing creative support, developing applications, or communication strategies, we stand together as a collective force.</p>
           </div>
       </section>

       <section class="work">
            <div class="hero-body">
            <div class="wbl"><lottie-player src="LNS_00.json" autoplay loop></lottie-player></div>
              <div class="columns is-vcentered intro">
                <div class="column"><h2 class="stitle has-text-black has-text-left">Work</h2></div>
                <div class="column">
                  <p class="has-text-black has-text-left">At the heart of our approach is a deep understanding of the importance of user experience. We believe that every design and technology decision should be driven by the user's needs, wants, and expectations. We create digital products and brand experiences that are not only visually compelling but also highly functional.</p>
                </div>
              </div>
            </div>
            <div class="columns is-gapless is-multiline">
              <div class="column is-one-third"><img src="img/grid001.jpg"/></div>
              <div class="column is-one-third"><img src="img/grid001.jpg"/></div>
              <div class="column is-one-third"><img src="img/grid001.jpg"/></div>
              <div class="column is-one-third"><img src="img/grid001.jpg"/></div>
              <div class="column is-one-third"><img src="img/grid001.jpg"/></div>
              <div class="column is-one-third"><img src="img/grid001.jpg"/></div>
              <div class="column is-one-third"><img src="img/grid001.jpg"/></div>
              <div class="column is-one-third"><img src="img/grid001.jpg"/></div>
              <div class="column is-one-third"><img src="img/grid001.jpg"/></div>
            </div>
        </section>

        <section class="section solves is-fullheight">
          <div class="hero-body">
            <div class="columns is-vcentered">
              <div class="column is-half"><h2 class="stitle has-text-white has-text-left">Solves</h2></div>
              <div class="column is-half">
                <p class="has-text-left has-text-white">We strive to foster the growth and prosperity of businesses, recognizing that the key to success lies in cultivating an emotional connection between brands and their consumers. Through expertise and ingenuity, we craft digital ideas, products, and experiences designed to emanate joy and kindle the imagination.</p>
              </div>
              </div>
              <div class="wbl"><lottie-player src="LNS_00.json" autoplay loop></lottie-player></div>

          </div>

          <div class="wrapper">
            <div class="columns">
              <div class="column">
                <h3 class="title has-text-white">Digital Products</h3>
                <p>At the heart of our work lies a comprehensive suite of services that spans the full spectrum of digital application design and development. From ideation and conceptualization to the intricate art of user architecture, we employ an overarching approach that encompasses every aspect of modern-day application development.</p>
                <h4 class="title is-4">Name Project</h4>
              </div>
              <div class="column">
                <h3 class="title has-text-white">Emerging Technologies</h3>
                <p>Appetite for innovation – From AI-powered experiences, to augmented and virtual reality installations, to creating captivating in-situ visualizations, we will delve deep to unlock the best approach to challenges. </p>
                <h4 class="title is-4">Name Project</h4>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <h3 class="title has-text-white">Interactive Installations</h3>
                <p>Whether operating within the digital realm or in-person, we take a dedicated approach that is tailored precisely to the project needs, amplifying brand messaging and igniting conversation around your campaign. Our ideal is to create experiences that leverage a profound and lasting impact on the target audience and set the stage for future success.</p>
                <h4 class="title is-4">Name Project</h4>
              </div>
              <div class="column">
                <h3 class="title has-text-white">Content</h3>
                <p>Weaving beans messages into compelling narratives to engage and inspire. As a one-stop-shop for all your content needs, we offer a comprehensive suite of services, ranging from the full spectrum of AV services to copywriting and design.</p>
                <h4 class="title is-4">Name Project</h4>
              </div>
            </div>

          </div>

         </section>

         <section class="hero section is-fullheight has-background-white partners">
           <div class="wrapper">
             <h2 class="stitle has-text-black has-text-left">Partners.</h2>
             <div class="columns">
                <div class="column"><p class="has-text-black has-text-left">Two decades of partnering with some of the most recognizable, influential and forward companies in the world. Here’s to all!</p></div>
                <div class="column"></div>
              </div>

             <div class="columns is-gapless is-multiline logos">
               <div class="column is-2"><img src="img/mattel.svg"/></div>
               <div class="column is-2"><img src="img/mattel.svg"/></div>
               <div class="column is-2"><img src="img/mattel.svg"/></div>
               <div class="column is-2"><img src="img/mattel.svg"/></div>
               <div class="column is-2"><img src="img/mattel.svg"/></div>
               <div class="column is-2"><img src="img/mattel.svg"/></div>
               <div class="column is-2"><img src="img/mattel.svg"/></div>
               <div class="column is-2"><img src="img/mattel.svg"/></div>
               <div class="column is-2"><img src="img/mattel.svg"/></div>
               <div class="column is-2"><img src="img/mattel.svg"/></div>
               <div class="column is-2"><img src="img/mattel.svg"/></div>
               <div class="column is-2"><img src="img/mattel.svg"/></div>
             </div>

           </div>




          </section>

          <section class="hero contact section is-fullheight">
            <h2 class="title is-2 has-text-white"></h2>

            <div class="columns <hr>">
              <div class="column"><h2 class="stitle has-text-white has-text-left">Let's Talk!</h2></div>
              <div class="column"><p class="has-text-left">Your thoughts and feedback are invaluable to us, and we would love to have the opportunity to share more of our work with you. We will be delighted to showcase our portfolio and collaborate with you to create exceptional digital experiences.</p></div>
            </div>
            <br/>

            <div class="columns">
                   <div class="column"><h3 class="title has-text-white">New business</h3><p><a href="#">sarah@dutchmonaco.com</a></p></div>
                   <div class="column"><h3 class="title has-text-white">Press & general inquiries</h3><p><a href="#">hello@dutchmonaco.com</a></p></div>
                   <div class="column"><h3 class="title has-text-white">Looking for a job?</h3><p><a href="#">hello@dutchmonaco.com</a></p></div>

                   </div>

                   <br/>

           </section>





           </div>
  );
}

export default App;
